import type { Ref } from 'vue'

export type BannerType =
  | 'desktopHomePrimaryAuthenticated'
  | 'desktopHomePrimaryNotAuthenticated'
  | 'desktopHomeSecondary'
  | 'desktopCasino'
  | 'desktopSport'
  | 'desktopRegistration'
  | 'mobileCasino'
  | 'mobileMenu'
  | 'mobileHomeAuthenticated'
  | 'mobileHomeNotAuthenticated'

export interface Banner {
  type: BannerType
  url: string
  alt?: string
  title: string | null
  publicUploadUrl: string
}

export function useBanners(type: BannerType) {
  const { locale } = useI18n()

  const { data: banners, pending } = useStFetch('/banner/find', {
    method: 'post',
    body: computed(() => ({
      language: locale.value as any,
      types: [type],
    })),
    default: () => [],
  })

  return {
    banners: banners as Ref<Banner[]>,
    isLoading: computed(() => pending.value && !banners.value.length),
  }
}
